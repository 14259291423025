import { createSlice } from '@reduxjs/toolkit';
import { SortingActivitiesType } from 'pages/manager-pages/activities/types';
import { ACTIVITIES_SERVICES_REDUCER_NAMESPACES } from '../constants';
export var initialState = {
    searchString: '',
    statusSorting: SortingActivitiesType.isActive,
    typeSorting: undefined,
    isShowForm: false,
    categoryId: null,
};
var activitiesFilterServiceState = createSlice({
    name: ACTIVITIES_SERVICES_REDUCER_NAMESPACES.filter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        changeStatusSorting: function (state, action) {
            state.statusSorting = action.payload;
        },
        changeTypeSorting: function (state, action) {
            state.typeSorting = action.payload;
        },
        changeCategoryId: function (state, action) {
            state.categoryId = action.payload;
        },
        openModalForm: function (state) {
            state.isShowForm = true;
        },
        closeModalForm: function (state) {
            state.isShowForm = false;
        },
        resetFilter: function () { return initialState; },
    },
});
export var actions = activitiesFilterServiceState.actions;
export default activitiesFilterServiceState.reducer;
