var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNotificationDrawerActions } from 'common/layouts/main-layout/service/notification-drawer-service/hooks';
import { profileServiceMainDataSelector, profileServiceUserHasUnreadNotificationsSelector, } from 'common/services/profile-service/selectors';
import { Box } from 'ui-kit/box';
import { Typography } from 'ui-kit/typography';
import { UserAvatar } from 'ui-kit/user-avatar';
import { Icon } from 'ui-kit/icon';
import { BellIcon } from 'common/icons';
import { LogoutIcon } from 'common/layouts/icons';
import { ControlButton } from '../../../control-button';
import { WithNotificationWrapper } from '../../../with-notification-wrapper';
import { userService } from 'common/utils/user-service';
import { checkEqual } from 'common/helpers/checkEqual';
import { getCapitalFirstLetter } from 'common/helpers/get-capital-first-latter';
import { LOGOUT_BUTTON_TESTID } from 'common/layouts/main-layout/constants';
export var Header = memo(function () {
    var t = useTranslation().t;
    var openDrawer = useNotificationDrawerActions().openDrawer;
    var _a = useSelector(profileServiceMainDataSelector, checkEqual), image = _a.image, firstName = _a.firstName, lastName = _a.lastName;
    return (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ display: "flex", alignItems: "center", wrap: "nowrap" }, { children: [_jsx(Typography, __assign({ variant: "h5" }, { children: t('common.menu.showMore') })), _jsx(Box, __assign({ pl: 4, marginAuto: "left" }, { children: _jsx(ControlButton, { isReverseIcon: true, label: t('common.menu.logout'), icon: LogoutIcon, dataTestid: LOGOUT_BUTTON_TESTID, onClick: userService.doLogout }) }))] })), _jsxs(Box, __assign({ pt: 12, display: "flex", alignItems: "center", wrap: "nowrap" }, { children: [_jsx(UserAvatar, { mr: 6, imagePath: image === null || image === void 0 ? void 0 : image.fullPath, firstName: firstName, lastName: lastName }), _jsx(Typography, __assign({ variant: "h6", component: "span" }, { children: "".concat(lastName, " ").concat(getCapitalFirstLetter(firstName), ".") })), _jsx(Box, __assign({ pl: 4, marginAuto: "left" }, { children: _jsx(WithNotificationWrapper, __assign({ onClick: function () { return openDrawer(); }, hasNotificationSelector: profileServiceUserHasUnreadNotificationsSelector }, { children: _jsx(Icon, { color: "text", component: BellIcon, width: 1.25, viewBox: "0 0 20 20" }) })) }))] }))] }));
});
