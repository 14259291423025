import { createSlice } from '@reduxjs/toolkit';
import { USER_ACTIVITIES_REDUCER_NAMESPACES } from '../constants';
export var initialState = {
    searchString: '',
    sortType: undefined,
    categoryId: null,
};
var userActivitiesFilterServiceState = createSlice({
    name: USER_ACTIVITIES_REDUCER_NAMESPACES.userActivitiesFilter,
    initialState: initialState,
    reducers: {
        changeSearchString: function (state, action) {
            state.searchString = action.payload;
        },
        changeSortType: function (state, action) {
            state.sortType = action.payload;
        },
        changeCategoryId: function (state, action) {
            state.categoryId = action.payload;
        },
        resetFilter: function () { return initialState; },
    },
});
export var actions = userActivitiesFilterServiceState.actions;
export default userActivitiesFilterServiceState.reducer;
