import { useEffect } from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useToastActions } from 'common/services/toast-service/hooks';
export var FormErrorsToast = function (_a) {
    var title = _a.title, message = _a.message;
    var addErrorToast = useToastActions().addErrorToast;
    var _b = useFormState(), submitFailed = _b.submitFailed, valid = _b.valid;
    var t = useTranslation().t;
    useEffect(function () {
        if (submitFailed && !valid) {
            addErrorToast({
                title: title || t('common.formErrorsToastDef.title'),
                message: message || t('common.formErrorsToastDef.message'),
            });
        }
    }, [addErrorToast, t, submitFailed, message, title]);
    return null;
};
