var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNotificationDrawerActions } from '../../service/notification-drawer-service/hooks';
import { profileServiceTealBalanceSelector, profileServiceThanksBalanceSelector, profileServiceUserHasUnreadNotificationsSelector, } from 'common/services/profile-service/selectors';
import { Box } from 'ui-kit/box';
import { Popup } from 'ui-kit/popup';
import { FormattedNumber } from 'ui-kit/formatted-number';
import { Divider } from 'ui-kit/divider';
import { Icon } from 'ui-kit/icon';
import { BellIcon, ProfileIcon } from 'common/icons';
import { CurrentUserAvatar } from 'common/components/current-user-avatar';
import { Logo } from 'common/components/logo';
import { CurrencyIcon } from 'common/components/currency-icon';
import { ThanksCurrencyIcon } from 'common/components/thanks-currency-icon';
import { PopupSettingListItem } from 'common/components/popup-setting-list-item';
import { WithNotificationWrapper } from '../with-notification-wrapper';
import { CheckRenderThanksBalance } from 'common/components/check-render-thanks-balance';
import styles from './mobile-header.module.scss';
var UserInfo = function (_a) {
    var pl = _a.pl;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var openDrawer = useNotificationDrawerActions().openDrawer;
    var tealBalance = useSelector(profileServiceTealBalanceSelector);
    var thankBalance = useSelector(profileServiceThanksBalanceSelector);
    return (_jsxs(Box, __assign({ pl: pl, alignItems: "center", wrap: "nowrap" }, { children: [_jsxs(CheckRenderThanksBalance, { children: [_jsx(FormattedNumber, { value: thankBalance, variant: "h6", component: "span", color: "primary", postfix: _jsx(ThanksCurrencyIcon, {}) }), _jsx(Divider, { className: styles.divider, orientation: "vertical", mr: 4, ml: 4 })] }), _jsx(FormattedNumber, { value: tealBalance, variant: "h6", component: "span", color: "primary", postfix: _jsx(CurrencyIcon, {}) }), _jsx(Popup, __assign({ renderButton: function (_show, onClick) { return (_jsx(WithNotificationWrapper, __assign({ ml: 2, wrapperClassName: styles.avatar, onClickCapture: onClick, hasNotificationSelector: profileServiceUserHasUnreadNotificationsSelector }, { children: _jsx(CurrentUserAvatar, {}) }))); } }, { children: _jsxs("ul", { children: [_jsx(PopupSettingListItem, { text: t('common.mobileHeaderMenu.profile'), prefix: _jsx(Icon, { className: styles.profileIcon, color: "text", component: ProfileIcon, width: 1.25, viewBox: "0 0 20 20" }), onClick: function () { return navigate('/profile'); } }), _jsx(PopupSettingListItem, { text: t('common.mobileHeaderMenu.notifications'), prefix: _jsx(WithNotificationWrapper, __assign({ mr: 4, hasNotificationSelector: profileServiceUserHasUnreadNotificationsSelector }, { children: _jsx(Icon, { color: "text", component: BellIcon, width: 1.25, viewBox: "0 0 20 20" }) })), onClick: function () { return openDrawer(); } })] }) }))] })));
};
export var MobileHeader = function () { return (_jsxs(Box, __assign({ className: styles.header, display: "flex", wrap: "nowrap", alignItems: "center", justify: "space-between", component: "header" }, { children: [_jsx(Link, __assign({ className: styles.link, to: "/" }, { children: _jsx(Logo, { size: "small" }) })), _jsx(UserInfo, { pl: 8 })] }))); };
