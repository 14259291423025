var _a;
import { combineReducers } from 'redux';
import activitiesServiceReducer from './activities-service/reducer';
import activitiesFilterServiceReducer from './filter-service/reducer';
import activitiesCategorieForFormServiceReducer from './activities-categories-for-form-service/reducer';
import { ACTIVITIES_SERVICES_REDUCER_NAMESPACES } from './constants';
export var activitiesServicesRootReducer = combineReducers((_a = {},
    _a[ACTIVITIES_SERVICES_REDUCER_NAMESPACES.filter] = activitiesFilterServiceReducer,
    _a[ACTIVITIES_SERVICES_REDUCER_NAMESPACES.activitesList] = activitiesServiceReducer,
    _a[ACTIVITIES_SERVICES_REDUCER_NAMESPACES.activitiesCategoriesForForm] = activitiesCategorieForFormServiceReducer,
    _a));
